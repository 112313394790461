
.file-drop-zone {
    margin-top: 10px;
    height: 60px;
    font-size: 12px;
    color: #a0a0a0;
    width: 100%;
}

.file-drop-zone div {
    border: 1px dashed gray;
}

.file-drop-zone h4{
    font-size: 11pt;
    color: #a0a0a0;
}