.wrapMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5em;
}

.inputText {
    display: block;
    width: 15em;
    height: 2.3em;
    text-align:center;
    font-size: 1.3em;
    margin: 1em;
    border-radius:.3em;
    background-color: #f1f1f1
}

.inputButton {
    display: flex;
    margin-top:1.3em;
    background-color: indigo;
    border:.1em solid white;
    border-radius:.3em;
    padding: .8em 2.8em .8em 2.8em;
    color: white;

    &.bgWhite{
        background-color:white;
        color: indigo;
        border: .1em solid indigo;
    }

    &:hover {
        border: .1em solid indigo;
        color: indigo;
        background-color: white;
    }
}

.loginWrapButtons {
    display: flex;
    align-items: center;
    flex-direction: column;
}