
.progress-tick-body {
    width: 6px;
    height: 16px;
    margin-left: 2px;
    margin-right: 2px;
}

.progress-tick-body-fill {
    width: 6px;
    height: 16px;
    border-radius: 3px;
}