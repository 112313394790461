body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapMenu{display:flex;flex-direction:column;align-items:center;margin:5em}.inputText{display:block;width:15em;height:2.3em;text-align:center;font-size:1.3em;margin:1em;border-radius:.3em;background-color:#f1f1f1}.inputButton{display:flex;margin-top:1.3em;background-color:indigo;border:.1em solid white;border-radius:.3em;padding:.8em 2.8em .8em 2.8em;color:white}.inputButton.bgWhite{background-color:white;color:indigo;border:.1em solid indigo}.inputButton:hover{border:.1em solid indigo;color:indigo;background-color:white}.loginWrapButtons{display:flex;align-items:center;flex-direction:column}


.file-drop-zone {
    margin-top: 10px;
    height: 60px;
    font-size: 12px;
    color: #a0a0a0;
    width: 100%;
}

.file-drop-zone div {
    border: 1px dashed gray;
}

.file-drop-zone h4{
    font-size: 11pt;
    color: #a0a0a0;
}

.st0 {
    fill:url(#SVGID_1_);
}

.st1 {
    fill:#D81222;
}


.progress-tick-body {
    width: 6px;
    height: 16px;
    margin-left: 2px;
    margin-right: 2px;
}

.progress-tick-body-fill {
    width: 6px;
    height: 16px;
    border-radius: 3px;
}

.stll0 {
    fill: #6d6e70;
}

.stll1 {
    fill: #231f20;
}

